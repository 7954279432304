<template>
    <section>
        <div v-if="pdfActive">
            <embed src="https://soilrisk-landing-page.s3.eu-west-2.amazonaws.com/CaseStudy-compressed.pdf" class="pdf-container" type="application/pdf">
        </div>
        <div v-if="!pdfActive">
            ERROR! Invalid link
        </div>
    </section>
    
</template>


<script>
export default {
    name:"CaseStudy",
    data() {
        return {
            pdfActive: false,
        };
    },
    mounted: async function(){

        const res = await fetch(`https://soilrisk.com:1880/verify?link_id=${this.$route.query.link}`);
    
        res.json().then(value=>{
            if(value.message!='not found'){
                this.pdfActive=true  
            }
        }
        ) 
    },
}
</script>

