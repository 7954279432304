<template>
  	<div>
		<div v-if="!case_study">
			<FreeTour />
			<Video />
			<Benefits />
			<Application />
			<HowItWorks />
			<Partners /> 
			<Team />
			<Contact />
		</div>
		<div v-if="case_study">
			<CaseStudy />
		</div>
		<Cookies @allowCookies="loadCookies" />
  	</div>
</template>

<script>
import FreeTour from "../components/Free_tour.vue";
import Video from "../components/Video.vue";
import Benefits from "../components/Benefits.vue";
import Application from "../components/Application.vue";
import HowItWorks from "../components/How_It_Works.vue";
import Partners from "../components/Partners.vue";
import Team from "../components/Team.vue";
import Contact from "../components/Contact.vue";
import CaseStudy from "../components/CaseStudy.vue";
import Cookies from "../components/Cookies.vue";



export default {
	name: "App",
	data() {
		return{
			case_study: true,
		};
	},
	components: {
		FreeTour,
		Video,
		Benefits,
		Application,
		HowItWorks,
		Contact,
		Partners,
		Team,
		CaseStudy,
		Cookies
	},
	mounted(){
		if(!this.$route.query.link){
			this.case_study=false;
			document.getElementsByTagName("html")[0].classList.add("html-background");
		}
	},
	methods: {
		loadCookies(){
			window.dataLayer = window.dataLayer || [];
			function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); 
			gtag('config', 'G-S90Y1X4C9C'); 
		}
	}
};
</script>