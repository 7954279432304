<template>
    <section class="case-study-background-color section-margin">

        <div class="container has-text-centered">
            <p class="partners-title is-size-2 my-5">BECOME A PARTNER</p>
        </div>
        
        <div class="columns has-text-right-desktop has-text-centered-mobile mt-6 video-columns is-multiline">
            <div class="column partners-icon-container">
                <img src="../assets/images/handshake3.svg" class="partners-image mt-5" />
            </div>
            <div class="column no-padding">
                <div class="partners-text-container">
                    <p class="partners-text is-size-4-desktop is-size-5-touch mt-1"><i class="mdi mdi-chevron-right arrow-right-partner"/> We’re looking for international partners.</p>
                    <p class="partners-text is-size-4-desktop is-size-5-touch mt-1"><i class="mdi mdi-chevron-right arrow-right-partner"/> Join our business partner program.</p>
                    <p class="partners-text is-size-4-desktop is-size-5-touch mt-1"><i class="mdi mdi-chevron-right arrow-right-partner"/> Receive all the support you need.</p>
                    <p class="partners-text is-size-4-desktop is-size-5-touch mt-1"><i class="mdi mdi-chevron-right arrow-right-partner"/> Grow Soilrisk in your territory.</p>
                </div>
                <div class="partners-button mt-6">
                    <b-button rounded class="grad-btn" @click="partnersActive()">
                        <span class="parterns-button-text is-size-4-desktop is-size-5-touch">APPLY</span>
                    </b-button>
                </div>
            </div>
        </div>





        <div v-show="partners" class="case-form-container home-scroll" @click="closePartners()"> 
            <span @click="closePartners()" class="exit-btn has-z-index2" title="Close Modal">&times;</span>
			<div class="columns is-centered home-padding" >
                <div class="column is-half">
                    <div class="card home-form-card" @mouseenter="enterFormPartners=true" @mouseleave="enterFormPartners=false">
                        <div class="card-content home-form-card-content">

                            <form name="SoilriskBecomeAPartner" netlify method="post" netlify-honeypot="bot-field" autocomplete="off">
                                <input type="hidden" name="form-name" value="SoilriskBecomeAPartner" />

                                <p class="is-size-2 is-size-5-mobile has-text-centered tour-modal-field-title">BECOME A PARTNER</p>

                                <p class="tour-modal-field-title is-size-5 is-size-6-mobile has-text-centered mt-4"><small>*</small>Name</p>
                                <input type="text" name="name" class="home-input-text is-size-6" required>

                                <p class="tour-modal-field-title is-size-5 is-size-6-mobile has-text-centered mt-2"><small>*</small>Entity</p>
                                <input type="text" name="entity" class="home-input-text is-size-6" required>

                                <p class="tour-modal-field-title is-size-5 is-size-6-mobile has-text-centered mt-2">Job title</p>
                                <input type="text" name="jobtitle" class="home-input-text is-size-6">

                                <p class="tour-modal-field-title is-size-5 is-size-6-mobile has-text-centered mt-2"><small>*</small>Email</p>
                                <input type="email" name="email" class="home-input-text is-size-6" required>

                                <p class="tour-modal-field-title is-size-5 is-size-6-mobile has-text-centered mt-2">Message</p>
                                <textarea name="message" class="home-input-textarea is-size-6">
                                </textarea>

                                <div class="container has-text-centered mt-3">
                                    <!-- <b-field class="tour-modal-field-title mr-3"> 
                                        <b-checkbox required v-model="partnerData">
                                            <p class="is-size-6 is-size-7-mobile">*I agree to be contacted using the data I provide</p>
                                        </b-checkbox>
                                    </b-field> -->
                                    <div class="control">
                                        <label class="is-size-6 is-size-7-mobile tour-modal-field-title">
                                            <input required type="checkbox" name="policy" />
                                                *I accept the terms, conditions and privacy policy
                                        </label>
                                    </div>

                                    <div class="mt-2">
                                        <router-link :to="{ name: 'PrivacyPolicy'}" target="_blank" class="is-size-6 is-size-7-mobile privacy-policy">
                                            *Privacy Policy and Protection of Personal Data
                                        </router-link>
                                    </div>


                                </div>

                                <div class="has-text-centered">
                                    
                                    <button class="button grad-btn is-rounded has-z-index2 mt-4" type="submit">
                                        <span class=" is-size-4 is-size-6-mobile">
                                            APPLY
                                        </span>
                                    </button>
                                </div>
                            </form>
                            
                            
                        </div>
                    </div>  
                </div>
            </div>
		</div>  


    </section>
</template>

<script>
export default {
    data() {
    return {
        partners: false,
        enterFormPartners: false,
    };
  },
  methods: {
    partnersActive(){
        this.partners=true;
        document.getElementsByTagName("html")[0].classList.add("overflow-hidden");
        document.body.classList.add("overflow-hidden");
        // document.getElementsByClassName("nav-home")[0].classList.add("nodisplay");
        
    },
	exitPartners(){

        document.getElementsByTagName("html")[0].classList.remove("overflow-hidden");
        document.body.classList.remove("overflow-hidden");	
        // document.getElementsByClassName("nav-home")[0].classList.remove("nodisplay");
        this.partners=false;
	},
	// async applyPartners(){
	// 	//FIELDS REQUIRED TO SEND EMAIL
	// 	if(this.partnerName.length >0 && this.partnerEntity.length >0 && this.partnerEmail.length >0 && this.partnerData==true && this.partnerConditions==true){
    //         // EMAIL VERIFICATION

    //         if(!this.partnerEmail.match(/\S+@\S+\.\S+/)){ //Expression made with regexr
    //             this.$buefy.toast.open({
    //                 duration: 3000,
    //                 message: `Invalid e-mail`,
    //                 type: 'is-danger'
    //             })
    //         }
            
    //         else{
                
    //             const res = await fetch(`https://soilrisk.com:1880/partner?email=${this.partnerEmail}&name=${this.partnerName}&entity=${this.partnerEntity}&jobtitle=${this.partnerJobTitle}&message=${this.partnerMessage}`);
    //             console.log(res.json())


    //             this.$buefy.toast.open({
    //                 duration: 3000,
    //                 message: `Your submission has been sent, we will contact you soon`,
    //                 type: 'is-success'
    //             })
    //             this.exitPartners();
    //         }
            
            
            
	// 	}
    //     //Else mandatory fields are empty
    //     else{
    //         this.$buefy.toast.open({
    //             duration: 3000,
    //             message: `Please fill in all mandatory fields`,
    //             type: 'is-danger'
    //         })
    //     }
        
        
	// },
    closePartners(){
        if(!this.enterFormPartners){
            this.exitPartners();
        }
    }
  },
}
</script>