<template>
  <section class="hero is-fullheight home-background-image">
    <div class="hero-head">  

        <!-- <nav class="nav-home" v-show="showNavbar">
            
            <ul class="nav-links has-z-index5 pt-3">
                <a href="#"><li class="is-size-5 home-navbar-text mr-5">HOME</li></a>
                <a href="#case-study"><li class="is-size-5 home-navbar-text mr-5">CASE STUDY</li></a>
                <a href="#contact"><li class="is-size-5 home-navbar-text mr-5">ABOUT US</li></a>
                <a href="#team"><li class="is-size-5 home-navbar-text mr-5">TEAM</li></a>
                <a href="#links"><li class="is-size-5 home-navbar-text mr-5">LINKS</li></a>
                <a href="#"><li class="is-size-5 home-navbar-text mr-5">FREE TOUR</li></a>
            </ul> 
            <div class="burger mr-2 mt-2 has-z-index5" @click="navbarBurger()">
                <div class="line1"></div>
                <div class="line2"></div>
                <div class="line3"></div>
            </div> 
        </nav>  -->
        
        
    </div>
    
       
            
   
   

    <div class="hero-body">
        
		<div class="container has-text-centered">
			<img src="../assets/images/Group_1.svg" class="logo-img" />
			<p class="home-title is-size-2-desktop is-size-3-tablet is-size-4-mobile">
				PREDICTIVE MODEL APPLICATION FOR LANDSLIDES
			</p>
            
			<div style="width: 100%" class="mt-6">
                <b-button rounded class="grad-btn has-z-index2 mt-6" @click="freeTourActive()">
                    <span class="button-text is-size-4-desktop is-size-5-touch">
                        FREE TOUR
                    </span>
                </b-button>
			</div>
		</div>
    </div>

    <div class="hero-foot">
		
        <div class="container has-text-centered has-z-index2 mb-4">
            <a href="#video">
                <b-icon icon="chevron-down" class="arrow" size="is-large"/>
            </a>
        </div>
        
    </div>


		<div v-show="freeTour" class="home-form-container home-scroll" @click="closeCard()"> 
            <span @click="closeCard()" class="exit-btn has-z-index2" title="Close Modal">&times;</span>
			<div class="columns is-centered home-padding">
                <div class="column is-half">
                    <div class="card home-form-card" @mouseenter="enterForm=true" @mouseleave="enterForm=false">
                        <div class="card-content home-form-card-content">
                            <form name="SoilriskFreeTour" netlify method="post" netlify-honeypot="bot-field" autocomplete="off">
                                <input type="hidden" name="form-name" value="SoilriskFreeTour" />

                                <p class="is-size-2 is-size-5-mobile has-text-centered tour-modal-field-title">FREE TOUR</p>
                                <p class="tour-modal-field-title is-size-5 is-size-6-mobile has-text-centered mt-4"><small>*</small>Name</p>
                                <input type="text" name="name" class="home-input-text is-size-6" required>

                                <p class="tour-modal-field-title is-size-5 is-size-6-mobile has-text-centered mt-2"><small>*</small>Entity</p>
                                <input type="text" name="entity" class="home-input-text is-size-6" required>

                                <p class="tour-modal-field-title is-size-5 is-size-6-mobile has-text-centered mt-2">Job title</p>
                                <input type="text" name="jobtitle" class="home-input-text is-size-6" required>

                                <p class="tour-modal-field-title is-size-5 is-size-6-mobile has-text-centered mt-2"><small>*</small>Email</p>
                                <input type="email" name="email" class="home-input-text is-size-6" required>

                                <p class="tour-modal-field-title is-size-5 is-size-6-mobile has-text-centered mt-2">Message</p>
                                <textarea name="message" class="home-input-textarea is-size-6">
                                </textarea>

                                <div class="container has-text-centered mt-3">
                                    <div class="control">

                                        <label class="tour-modal-field-title is-size-6 is-size-7-mobile mr-3"> 
                                            <input required name="contacted" type="checkbox" />
                                                *I agree to be contacted using the data I provide 
                                            
                                        </label>
                                    </div>
                                    <div class="control">

                                        <label class="tour-modal-field-title is-size-6 is-size-7-mobile">
                                            <input required name="policy" type="checkbox" />
                                                *I accept the terms, conditions and privacy policy
                                            
                                        </label>
                                    </div>
                                    <div class="mt-2">
                                        <router-link :to="{ name: 'PrivacyPolicy'}" target="_blank" class="is-size-6 is-size-7-mobile privacy-policy">
                                            *Privacy Policy and Protection of Personal Data
                                        </router-link>

                                    </div>
                                </div>

                                <div class="has-text-centered mt-3">
                                    <button class="button is-rounded grad-btn has-z-index2" type="submit">
                                        <span class=" is-size-4 is-size-6-mobile">
                                            APPLY
                                        </span>
                                    </button>
                                </div>
                            </form>
                            
                        </div>
                    </div>  
                </div>
            </div>
		</div>  
        <div class="color-bridge">
        </div>
  </section>
</template>


<script>
export default {
    
    data() {
        return {
            homeName: "",
            homeEntity: "",
            homeJobTitle: "",
            homeMessage: "",
            homeEmail: "",
            freeTour: false,
            homeData: false,
            homeConditions: false,
            enterForm: false,
            isNavBurgerActive: false,
            showNavbar: false,
        };
    },
    methods: {
        freeTourActive(){
            this.freeTour=true;
            document.getElementsByTagName("html")[0].classList.add("overflow-hidden");
            document.body.classList.add("overflow-hidden");
            
            // document.getElementsByClassName("nav-home")[0].classList.add("nodisplay");
            
        },
        exitFreeTour(){
            this.homeName= "";
            this.homeEntity= "";
            this.homeJobTitle= "";
            this.homeMessage= "";
            this.homeEmail= "";
            this.homeData=false;
            this.homeConditions=false;
            document.getElementsByTagName("html")[0].classList.remove("overflow-hidden");
            document.body.classList.remove("overflow-hidden");	
            // document.getElementsByClassName("nav-home")[0].classList.remove("nodisplay");
            this.freeTour=false;
        },
        async applyFreeTour(){
            //FIELDS REQUIRED TO SEND EMAIL
            // if(this.homeName.length >0 && this.homeEntity.length >0 && this.homeEmail.length >0 && this.homeData==true && this.homeConditions==true){
            //     // EMAIL VERIFICATION

            //     if(!this.homeEmail.match(/\S+@\S+\.\S+/)){ //Expression made with regexr
            //         this.$buefy.toast.open({
            //             duration: 3000,
            //             message: `Invalid e-mail`,
            //             type: 'is-danger'
            //         })
            //     }
                
            //     else{
                    
            //         const res = await fetch(`https://www.soilrisk.com:1880/free-tour-teste?email=${this.homeEmail}&name=${this.homeName}&entity=${this.homeEntity}&jobtitle=${this.homeJobTitle}&message=${this.homeMessage}`);
            //         console.log(res.json())


            //         this.$buefy.toast.open({
            //             duration: 3000,
            //             message: `Your submission has been sent, we will contact you soon`,
            //             type: 'is-success'
            //         })
            //         this.exitFreeTour();
            //     }
                
                
                
            // }
            // //Else mandatory fields are empty
            // else{
            //     this.$buefy.toast.open({
            //         duration: 3000,
            //         message: `Please fill in all mandatory fields`,
            //         type: 'is-danger'
            //     })
            // }
            
            
        },
        closeCard(){
            if(!this.enterForm){
                this.exitFreeTour();
            }
        },
        navbarBurger(){
            if(this.isNavBurgerActive==true){
                document.getElementsByClassName("nav-links")[0].classList.remove("nav-active");
                document.getElementsByClassName("burger")[0].classList.remove("toggle");

                const navLinks=document.querySelectorAll(".nav-links li");
                this.isNavBurgerActive=false;
                navLinks.forEach((link)=>{
                    
                    link.style.animation=''
                    
                    
                    
                });
            }
            else{
                document.getElementsByClassName("nav-links")[0].classList.add("nav-active");
                const navLinks=document.querySelectorAll(".nav-links li");

                navLinks.forEach((link, index)=>{
                
                    link.style.animation=`navLinkFade 0.5s ease forwards ${index /7 + 0.3}s`
                    
                });


                document.getElementsByClassName("burger")[0].classList.add("toggle");
                
                this.isNavBurgerActive=true;
            }
        },
        onScroll (){
            // const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
            const currentScrollPosition = window.scrollY
            if(currentScrollPosition>850){
                this.showNavbar=true
            }else{
                this.showNavbar=false
            }
        }
    },
    mounted(){
        window.addEventListener('scroll', this.onScroll)
    },
    beforeDestroy (){
        window.removeEventListener('scroll', this.onScroll)
    }

      

      
  
};
</script>


