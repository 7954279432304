import Vue from 'vue'
import App from './App.vue'
import Buefy from "buefy";
import router from "./router";
import VueRouter from 'vue-router'
import "./assets/scss/app.scss";
import cookieconsent from 'vue-cookieconsent-component'

Vue.config.productionTip = false
Vue.use(Buefy);
Vue.use(VueRouter);

Vue.component('cookie-consent', cookieconsent)

new Vue({
    router,
    render: h => h(App),
}).$mount('#app')

