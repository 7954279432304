<template>
    <section class="video-background section-margin" id="video">
        <div class="container has-text-centered has-z-index2">
            <p class="is-size-2-desktop is-size-3-touch video-text mb-6">WHAT IS SOILRISK?</p>
            <div class="columns video-columns is-multiline is-centered has-z-index2">
                <div class="column description-text-box mr-6 is-offset-1 has-z-index2">
                    <div class="has-z-index2">
                        <p class="is-size-5 has-z-index2">SOILRISK is a high-resolution Predictive Model Application for Landslides that gathers atmospheric and environmental data and converts it into valuable intelligence in order to forecast landslide occurrence and provide a warning system for prevention.</p>    
                        <p class="is-size-5 mt-3">SOILRISK is based on an innovative Machine Learning approach and a proprietary early-warning algorithm, using more than 20 conditioning factors dynamically. Among many others, Land Cover, Lithology, Vegetation, Moisture, Wind speed, Precipitation, Subsidence recurrently feed SOILRISK’s algorithm.</p> 
                    </div> 
                    <div class="btn-align-center">
                        <a href="#how-it-works">
                            <b-button rounded class="grad-btn mt-5">
                                <span class="is-size-5">LEARN MORE</span>  
                            </b-button>
                        </a> 
                    </div>
                </div>
                <div class="column">
                    <div class="container has-text-centered has-z-index2 is-one-third-desktop">   
                        <div v-if="videoActive" class="videoContainer">
                            <video width="659.5" height="401" class="video-form my-4" controls autoplay="autoplay" muted> 
                                <source src="../assets/videos/2021_05_05_videofinal_audio_ver02.mp4" type="video/mp4">
                                Video loading Error
                            </video>
                        </div>  
                        <div v-if="!videoActive">
                            <video width="659.5" height="401" class="video-form my-4" autoplay="autoplay" @click="videoActive=true" muted loop="true"> 
                                <source src="../assets/videos/videoPlay.mp4" type="video/mp4">
                                Video loading Error
                            </video>
                        </div>      
                    </div> 
                </div>
            </div>
        </div>
    </section>
</template>


<script>
export default {
    data(){
        return{
           videoActive:false, 
        }
    },
}
</script>
