<template>
  <section>
    <div
      v-show="cookies"
      class="cookies-form-container px-4"
      @click="closeCookies()"
    >
      <span
        @click="closeCookies()"
        class="exit-btn has-z-index2"
        title="Close Modal"
        >&times;</span
      >
      <div class="columns is-centered cookies-padding">
        <div class="column">
          <div
            class="card home-form-card"
            @mouseenter="entercookies = true"
            @mouseleave="entercookies = false"
          >
            <div class="card-content home-form-card-content">
              <p class="cookies-title pt-1 is-size-4">COOKIES POLICY</p>
              <div class="cookies-main-text">
                <p class="mt-2">
                  Eyecon - Software, Lda. (“Eyecon”), a company with its head
                  office at Rua Maria Angelina Turlu, 11, 9700-575 São Mateus da
                  Calheta, Portugal, and registered under the corporate number
                  514 572 426, provides the website 
				  <span class="privacy-color-yellow" style="cursor: pointer" @click="exitCookies()">
				  www.soilrisk.com</span>
                  (the “Website”), acting as the data controller of the personal
                  data, whenever collected, as detailed in this Policy.
                </p>
                <p class="mt-2">
                  The Website uses cookies to maintain and improve the
                  performance and experience of its users, analyzing metrics of
                  their behavior, in an anonymous and aggregate way. This
                  cookies Policy (the “cookies Policy” or “Policy”) gives you
                  more information about how we collect, store, and use the
                  cookies on this Website, and may, whenever necessary, be
                  complemented by the applicable legislation in this matter.
                </p>
                <p class="mt-2">
                  Please note that this Policy is not applicable to third-party
                  websites that may be accessed through links that are available
                  on this Website.
                </p>
                <p class="mt-2">
                  In order to ensure the rights of its users and the privacy and
                  protection of their personal data, the Website provides a tool
                  that allows users to provide their consent or revoke the
                  provided consent at any time:
                  <span
                    @click="animationCookies()"
                    style="cursor: pointer"
                    class="change-consent"
                    >Change my consent</span
                  >
                </p>

                <p class="mt-2 is-size-5">1. What are cookies?</p>
                <p class="mt-2">
                  Cookies are small text files, usually in .html format, that
                  are stored by a website on your device (computer, laptop,
                  smartphone, tablet, etc.). This Policy regulates the use of
                  all the cookies and other technologies, such as tags, pixel
                  tags, web beacons, user ids, and other technologies that may
                  be developed (“<b>Cookies</b>”).
                </p>
                <p class="mt-2">
                  On each visit to the Website, your internet browser allows
                  access to the Cookies installed on your device, allowing the
                  recognition and memorization of your identity (when
                  applicable), as well as the immediate activation of your usage
                  preferences.
                  <b
                    >These Cookies will only be installed with your express
                    consent</b
                  >, except where they are strictly necessary for the
                  functioning of the Website.
                </p>
                <p class="mt-2">
                  Regarding strictly necessary Cookies, in case you wish to
                  delete them or automatically set their blocking, you can do so
                  in the "Help" menu of your browser. However, if you do not
                  allow the use of these Cookies, please consider that you will
                  be increasing your device’s vulnerability to intrusions and
                  security breaches, and you may also be unable to activate some
                  features provided by the Website.
                </p>

                <p class="mt-2 is-size-5">2. Who owns the cookies?</p>
                <p class="mt-2">
                  Each Cookie has a domain name associated with it and can be
                  classified, with regard to its origin, by the following
                  categories:
                </p>
                <ul style="list-style-type: disc" class="ml-5">
                  <li>
                    <b>First party cookies:</b> cookies managed and stored by
                    their owner or its affiliates. In this particular case,
                    without prejudice to more detail in the link provided below,
                    for example, the Website manages cookies with the purpose of
                    ensuring the security and reliability of the website,
                    generating performance and analysis information.
                  </li>
                  <li>
                    <b>Third party cookies:</b> cookies managed and stored by
                    entities that have content or systems integrated into the
                    Website.
                  </li>
                </ul>
                <p class="mt-2 no-third-party-cookies">
                  <b
                    >This Website does not read or install third-party
                    cookies.</b
                  >
                </p>
                <p class="mt-2">
                  To know more about the cookies that we collect, please see the
                  list of cookies available at the end of this Policy.
                </p>
                <p class="mt-2 is-size-5">3. What are cookies used for?</p>
                <p class="mt-2">
                  Cookies are used for different purposes and can be classified
                  according to their origin, purpose, and duration. For
                  informational purposes only, listed below are the normal
                  classifications of cookies. Nevertheless,
                  <b>the Website only reads and installs Analytical Cookies:</b>
                </p>
                <ul style="list-style-type: disc" class="ml-5">
                  <li>
                    <b>Strictly necessary Cookies</b> – these are technical
                    cookies, used for ensuring a smooth browsing of the website
                    and the smooth operation of all its applications (for
                    example, access to secure areas and exclusive content for
                    registered users), and include, namely, security cookies,
                    which protect the website from intrusions and cyberattacks.
                    Included in this category are also Cookies that allow the
                    proper functioning of the e-commerce functionalities, as
                    this is an essential component of those services. For these
                    cookies, consent is not collected, as the Website needs them
                    to provide an information society service.
                  </li>
                  <li>
                    <b>Preference Cookies</b> – used to store user information,
                    preventing them from having to retype that information every
                    time they enter the website (for example, memorizing logins,
                    activation of content provided by third-party websites when
                    applicable, to remind you via email that there are items in
                    your shopping cart). They are used in contact forms and in
                    the online account opening or registration process, and can
                    be deleted by users at any time. They allow the
                    customization of the website to the users' needs, namely
                    when memorizing the preferred language.
                  </li>
                  <li>
                    <b>Analytic Cookies</b> – used to analyze how users browse
                    our website and identify browsing trends for statistical
                    purposes and improvement actions. These cookies can also
                    contribute to building individual and collective consumer
                    profiles.
                  </li>
                  <li>
                    <b>Advertisement Cookies</b> (divided between first-party
                    and third-party cookies) – they are cookies that allow the
                    website to know the user's browsing habits and show
                    advertisements related to their profile, that is, they allow
                    the website to effectively manage its advertisements,
                    adapting its content to the user. In this context, the
                    website also uses third-party cookies in order to advertise
                    its products and services.
                  </li>
                  <li>
                    <b>Location Data</b> – when you expressly authorize it, data
                    about your location may also be collected to provide you
                    with a service that depends on the use of your location.
                  </li>
                </ul>

                <p class="mt-2 is-size-5">4. Retention period</p>
                <p class="mt-2">
                  Regarding their duration, cookies can be classified as:
                </p>
                <ul style="list-style-type: disc" class="ml-6">
                  <li>
                    <b>Session cookies:</b> stored until the browser is closed
                    or the session ends;
                  </li>
                  <li>
                    <b>Persistent cookies:</b> stored for longer periods,
                    requiring action by the user in order to be eliminated from
                    the device.
                  </li>
                </ul>
                <p class="mt-2">
                  For more information about the retention period of the
                  persistent cookies we install, please see the list of cookies
                  available at the end of this Policy.
                </p>

                <p class="mt-2 is-size-5">
                  5. Controlling and blocking the cookies
                </p>
                <p class="mt-2">
                  When accessing the Website, the user will be asked which
                  cookies he/she wishes to accept. The user should be aware that
                  the lack of consent for some types of cookies – namely
                  functional cookies – may prevent or limit the functionalities
                  of our Website. You can change your preferences at any time:
                  <span
                    @click="animationCookies()"
                    style="cursor: pointer"
                    class="change-consent"
                    >Change my consent</span
                  >
                </p>
                <p class="mt-2">
                  Alternatively, most internet browsers also allow the control
                  of cookies through their settings, allowing users to view the
                  hosted cookies, as well as their elimination or blocking.
                  Please look in the &quot;help&quot; menu of your browser or
                  contact your browser provider. The Website is not responsible
                  for your browser settings or any updates it may require.
                </p>

                <p class="mt-2 is-size-5">6. Changes</p>
                <p class="mt-2 mb-3">
                  Eyecon reserves the right to, at any time, change, add, update
                  or delete, partially or totally, this information about
                  cookies. Whenever changes are relevant, the Website will
                  invite users to read the Policy and to enter their cookies
                  preference. For more information about cookies, please visit
                  <a href="https://www.allaboutcookies.org" target="_blank" class="privacy-color-yellow">
				  	www.allaboutcookies.org.
				  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- </div> -->
    <!-- see slots or props if you want to extend something  -->
    <cookie-consent v-if="cookiesbanner" id="cookie-banner">
      <template slot="message">
        <p class="cookie-banner px-3 is-size-7-mobile">
          To browse this website, you must accept the installation of cookies on
          your device, to enable us to analyze the performance of our website
          and better your browsing experience. To know more about cookies,
          please see our
          <a class="btn btn-link" @click="openCookies()">Cookies Policy.</a>
        </p>
        <button
          class="button is-light is-size-7-mobile mr-3"
          @click="rejectCookies()"
        >
          Reject All
        </button>
      </template>

      <template slot="button"> 
        <button
          class="button is-link is-size-7-mobile"
          @click="acceptCookies()"
        >
          Accept All
        </button>
      </template>
    </cookie-consent>
    <!-- <div class="cookie-banner py-3">
            
			<div class="columns is-centered">
				<div class="column is-half">
					<span>
						To browse this website, you must accept the installation of cookies on your device, to enable us to analyze the performance of our website and better your browsing experience.
						To know more about cookies, please see our <a class="btn btn-link" @click="openCookies()">Cookies Policy.</a> <button @click="acceptCookies()" class="cookies-accept ml-2">Accept</button>
					</span>
					
				</div>
			</div>
			
        </div> -->
  </section>
</template>

<script>
export default {
  name: "Cookies",
  data() {
    return {
      cookies: false,
      entercookies: false,
      cookiesbanner: true,
    };
  },
  methods: {
    closeCookies() {
      if (!this.entercookies) {
        this.exitCookies();
      }
    },
    openCookies() {
      this.cookies = true;
      document.getElementsByTagName("html")[0].classList.add("no-scroll");
    },
    exitCookies() {
      // document.getElementsByTagName("html")[0].classList.remove("overflow-hidden");
      document.getElementsByTagName("html")[0].classList.remove("no-scroll");
      document.body.classList.remove("overflow-hidden");
      // document.getElementsByClassName("nav-home")[0].classList.remove("nodisplay");
      this.cookies = false;
    },
    acceptCookies() {
      this.$emit("allowCookies");
      this.exitCookies();
    },
    rejectCookies() {
      this.exitCookies();
      this.cookiesbanner = false;
    },
    animationCookies(){
      document.getElementById('cookie-banner').classList.add("shake");
      setTimeout(() => {
        document.getElementById('cookie-banner').classList.remove("shake");
      }, 1000)
    }
  },
};
</script>