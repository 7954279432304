import Vue from "vue";
import VueRouter from "vue-router";


import Home from "../pages/Home.vue";
// import CaseStudy from "../pages/CaseStudy.vue";
import PrivacyPolicy from "../pages/PrivacyPolicy.vue";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "Home",
		component: Home
	},
	// {
	// 	path: "/casestudy/:casestudynumber/:link",
	// 	name: "CaseStudy",
	// 	component: CaseStudy
	// },
	{
		path: "/privacy-policy",
		name: "PrivacyPolicy",
		component: PrivacyPolicy
	}
];

const router = new VueRouter({
	mode: "hash",
	base: process.env.BASE_URL,
	routes
});

export default router;