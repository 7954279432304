<template>
    <section class="how-it-works-background-color section-margin-top" id="how-it-works">
        <!-- <div :class="'how-it-works-background pb-3 layer'+selectedIndex+'-bg'"> -->
            <div class="how-it-works-background pb-3 layer0-bg"></div>
            <div class="how-it-works-background pb-3 layer1-bg"></div>
            <div class="how-it-works-background pb-3 layer2-bg"></div>
            <div class="how-it-works-background pb-3 layer3-bg"></div>
                <div class="container has-text-centered">
                    
                    <p class="is-size-2-desktop is-size-3-touch how-it-works-title">OVERVIEW</p>
                    <div class="display-grid mt-6" >
                        <p v-if="selectedIndex==0" class="how-it-work-sub-title is-size-4">{{buttons[0]}}</p>
                        <p v-if="selectedIndex==1" class="how-it-work-sub-title is-size-4">{{buttons[1]}}</p>
                        <p v-if="selectedIndex==2" class="how-it-work-sub-title is-size-4">{{buttons[2]}}</p>
                        <p v-if="selectedIndex==3" class="how-it-work-sub-title is-size-4">{{buttons[3]}}</p>

                        <div class="level">
                            <div class="level-item">
                                <img src="../assets/images/line.svg" alt="line" />
                            </div>
                        </div>
                        
                        <p v-if="selectedIndex==0" class="is-size-5-desktop is-size-6-touch how-it-work-text "> {{overviewText[0]}} </p>
                        <p v-if="selectedIndex==1" class="is-size-5-desktop is-size-6-touch how-it-work-text ">{{overviewText[1]}}</p>
                        <p v-if="selectedIndex==2" class="is-size-5-desktop is-size-6-touch how-it-work-text ">{{overviewText[2]}}</p>
                        <p v-if="selectedIndex==3" class="is-size-5-desktop is-size-6-touch how-it-work-text ">{{overviewText[3]}}</p>
                    </div>
                    <div class="level overview-btn-margin">
                        <div class="level-item" v-for="(btn,index) in buttons" :key="'overview-btn-' + index"> 
                            <b-button rounded class="mt-5" :class="selectedIndex==index ? 'grad-btn': 'btn-not-selected'" @click="overview(index)"> 
                                <span class="is-size-5">{{btn}}</span> 
                            </b-button> 
                        </div>
                        
                        
                    </div>
                    
                    
                </div>
            
        <!-- </div> -->


        <div class="columns is-centered is-multiline is-gapless case-study-column" id="case-study">
            <div class="column is-one-third">
                <div class="card case-study-card-1">
                    <div class="card-content has-text-centered">
                        <p class="case-study-title is-size-3">
                            CASE<span class="study"> STUDY</span> 
                        </p>
                        <img src="../assets/images/line.svg" alt="line" />
                        <div class="case-study-sub-title is-size-6 mt-3">
                            <p>SOILRISK is helping to predict landslides in the Azores</p>
                        </div>
                        <div class="case-study-text my-5 is-size-6 mx-3">
                            <p>Every year thousands of lives are lost due to landslides on a global scale. In the last 30 years, more than 30 people lost their lives in the Azores due to landslides. Prolonged and heavy rainfall is the main trigger of most landslides. Traditionally, landslide susceptibility maps have low resolution, implying that early warnings are not precise enough to become actionable. The primary goal was to create a machine learning algorithm that would predict with accurate spatial resolution the most probable location of a landslide in the coming days.</p>
                        </div>
                        <div style="width: 100%">
                            <b-button rounded class="dark-blue-btn" @click="caseStudyActive()">
                                <span class="dark-blue-btn case-study-button-text is-size-5">DOWNLOAD</span>
                            </b-button>
                        </div>
                    </div>
                </div>
            </div>
                <div class="column is-one-third">
                    <div class="card case-study-card-2">
                        <div class="card-content has-text-centered">
                        <b-carousel :autoplay="false" :indicator="false" icon-size="is-medium" icon-next="chevron-right" >
                            <b-carousel-item v-for="(member, i) in members" :key="i">
                                <section :class="`hero is-small`">
                                    <div class="hero-body has-text-centered">
                                        <img :src="getImgUrl(member.photo)" alt="member photo" />
                                        <p  class="case-study-member-name is-size-4-desktop is-size-5-touch">
                                            <b>{{ member.Firstname }}</b> {{ member.Lastname }}
                                        </p>
                                        <p class="case-study-entity-text is-size-5-desktop is-size-6-touch mt-1">
                                            {{ member.entity }}
                                        </p>
                                        <p class="case-study-member-text is-size-6-desktop is-size-7-touch mt-4">
                                            {{ member.text }}
                                        </p>
                                    </div>
                                </section>
                            </b-carousel-item>
                        </b-carousel>
                        <img src="../assets/images/line.svg" alt="line" />
                    </div>
                </div>
            </div>
        </div>

      

        <div v-show="caseStudy" class="case-form-container home-scroll" @click="closeCaseStudy()">
            <span @click="closeCaseStudy()" class="exit-btn has-z-index2" title="Close Modal">&times;</span>
            <div class="columns is-centered home-padding">
                <div class="column is-half">
                    <div class="card home-form-card" @mouseenter="enterCaseStudy = true" @mouseleave="enterCaseStudy = false">
                        <div class="card-content home-form-card-content">

                            <form name="SoilriskCaseStudy" netlify method="post" netlify-honeypot="bot-field" autocomplete="off">
                                <input type="hidden" name="form-name" value="SoilriskCaseStudy" />

                                <p class="is-size-2 is-size-5-mobile has-text-centered tour-modal-field-title">CASE STUDY</p>

                                <p class="tour-modal-field-title is-size-5 is-size-6-mobile has-text-centered mt-4"><small>*</small>Name</p>
                                <input type="text" name="name" class="home-input-text is-size-6" required/>

                                <p class="tour-modal-field-title is-size-5 is-size-6-mobile has-text-centered mt-2"><small>*</small>Entity</p>
                                <input type="text" name="entity" class="home-input-text is-size-6" required/>

                                <p class="tour-modal-field-title is-size-5 is-size-6-mobile has-text-centered mt-2">Job title</p>
                                <input type="text" name="jobtitle" class="home-input-text is-size-6"/>

                                <p class="tour-modal-field-title is-size-5 is-size-6-mobile has-text-centered mt-2"><small>*</small>Email</p>
                                <input type="email" name="email" class="home-input-text is-size-6" required/>

                                <p class="tour-modal-field-title is-size-5 is-size-6-mobile has-text-centered mt-2">Message</p>
                                <textarea name="message" class="home-input-textarea is-size-6"></textarea>

                                <div class="container has-text-centered mt-3">
                                    <div class="control">
                                        <label class="tour-modal-field-title is-size-6 is-size-7-mobile mr-3">
                                            <input required name="contacted" type="checkbox" />
                                                *I agree to be contacted using the data I provide
                        
                                        </label>
                                    </div>

                                    <div class="control">
                                        <label class="tour-modal-field-title is-size-6 is-size-7-mobile">
                                            <input required name="policy" type="checkbox" />
                                                *I accept the terms, conditions and privacy policy
                                            
                                        </label>
                                    </div>

                                    <div class="mt-2">
                                        <router-link :to="{ name: 'PrivacyPolicy'}" target="_blank" class="is-size-6 is-size-7-mobile privacy-policy">
                                            *Privacy Policy and Protection of Personal Data
                                        </router-link>
                                    </div>

                                </div>

                                <div class="has-text-centered mt-4">
                                    <button class="button is-rounded grad-btn has-z-index2" type="submit">
                                        <span class="is-size-4 is-size-6-mobile">APPLY</span>
                                    </button>
                                </div>

                            </form>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        
    </section>
</template>

<script>
export default {
    data(){
        return{
            buttons:["DEFORMATION","VEGETATION", "SOIL MOISTURE", "WEATHER DATA"], 
            overviewText:[
                'High-quality ground-surface motion images derived using spaceborne InSAR play an important role in the accuracy improvement of the landslide susceptibility map, helping to quantify spatial and temporal trends of slope activity and identifying isolated instabilities.',
                "The type of vegetation, soil, and slope level are determinant to identify areas more susceptible to landslides. To settle the type of vegetation, the NDVI was derived from Sentinel-2 data.",
                "Moisture data is obtained using SAR data (Sentinel-1), using the Change Detection algorithm, in this way, for each pixel, a percentage of moisture is shown. Areas with low reliability are masked out using land cover maps.",
                "Precipitation and wind are the dynamic weather variables that feed the algorithm. Local meteorological stations data at an hourly scale can be used if available, obtaining, for each hour, a map of rain intensity and wind speed that covers the entire area of interest. For forecasting, satellite data weather services are used."
            ],
            selectedIndex:0,
            previousIndex:0,
            members: [
                {
                Firstname: "RUI",
                Lastname: "SILVA",
                entity:"SRPCBA - Civil Protection of the Azores",
                photo: "RuiSilva",
                text: '"We, the SRPCBA, think that Soilrisk will be a major tool in emergency planning and management in the Azores. Landslides emergencies, historically, are a cause of damage and injury in all Azorean islands."',
                },
                {
                Firstname: "JOÃO",
                Lastname: "RESENDES",
                entity:"Civil Protection Municipality of Povoação",
                photo: "JoaoResendes",
                text: '"The Soilrisk project, of which the Municipality of Povoação is a stakeholder, constitutes an asset, both in its application as a basic forecasting concept, and as an essential tool to assist in the decision-making of those who effectively decide on the ground, and consequently apply the appropriate response mechanisms to each situation."',
                },
            ],
            caseStudy: false,
            enterCaseStudy: false,
        }
    },
    mounted(){
        setTimeout(()=>{
            var elem = document.getElementsByClassName("layer1-bg")[0];
            elem.classList.add("is-hidden");

            var elem = document.getElementsByClassName("layer2-bg")[0];
            elem.classList.add("is-hidden");

            var elem = document.getElementsByClassName("layer3-bg")[0];
            elem.classList.add("is-hidden");
        },2000)
        
        
        
    },
    methods:{
        overview(index){
            
            this.selectedIndex=index;

            if(this.selectedIndex!=this.previousIndex){
                var elem = document.getElementsByClassName("layer"+index+"-bg")[0];
                elem.classList.remove("is-hidden");

                var elem = document.getElementsByClassName("layer"+this.previousIndex+"-bg")[0];
                elem.classList.add("is-hidden");

                this.previousIndex=this.selectedIndex;
            }
            
        },
        caseStudyActive() {
            this.caseStudy = true;
            document.getElementsByTagName("html")[0].classList.add("overflow-hidden");
            document.body.classList.add("overflow-hidden");
            // document.getElementsByClassName("nav-home")[0].classList.add("nodisplay");
        },
        exitCaseStudy() {
            document.getElementsByTagName("html")[0].classList.remove("overflow-hidden");
            document.body.classList.remove("overflow-hidden");
            // document.getElementsByClassName("nav-home")[0].classList.remove("nodisplay");
            this.caseStudy = false;
        },
        getImgUrl(img) {
        
            var images = require.context("../assets/images/stakeholders", false, /\.png$/);
            
            return images("./" + img + ".png");
        },
        closeCaseStudy() {
            if (!this.enterCaseStudy) {
                this.exitCaseStudy();
            }
        },
        // async applyCaseStudy(){
        //     //FIELDS REQUIRED TO SEND EMAIL
        //     if(this.caseName.length >0 && this.caseEntity.length >0 && this.caseEmail.length >0 && this.caseData==true && this.caseConditions==true){
        //         // EMAIL VERIFICATION
        //         if(!this.caseEmail.match(/\S+@\S+\.\S+/)){ //Expression made with regexr
        //             this.$buefy.toast.open({
        //                 duration: 3000,
        //                 message: `Invalid e-mail`,
        //                 type: 'is-danger'
        //             })
                
        //         }
        //         //Valid e-mail
        //         else{
                
        //             const res = await fetch(`https://soilrisk.com:1880/teste?to=${this.caseEmail}&name=${this.caseName}&entity=${this.caseEntity}&jobtitle=${this.caseJobTitle}&message=${this.caseMessage}`);
        //             console.log(res.json())


        //             this.$buefy.toast.open({
        //                 duration: 3000,
        //                 message: `An email has been sent with a link to access more information`,
        //                 type: 'is-success'
        //             })
        //             this.exitCaseStudy();
        //         }
                
                
                
        //     }
        //     //Else mandatory fields are empty
        //     else{
        //         this.$buefy.toast.open({
        //             duration: 3000,
        //             message: `Please fill in all mandatory fields`,
        //             type: 'is-danger'
        //         })
        //     }
        // }
    }
}
</script>